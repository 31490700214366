"use client";

import axios from "axios";
import { ArrowRightIcon, Facebook, Instagram, Twitter, Youtube } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useState, type FormEvent } from "react";

const API_URL = process.env.NEXT_PUBLIC_MICROGRAPHER_API_URL;

const API_KEY = process.env.NEXT_PUBLIC_MICROGRAPHER_API_KEY;

type SubscriberResponseData = {
	status_code: number;
	message: string;
};

type SubscriberResponse = {
	message: string;
	status_code: number;
	data: SubscriberResponseData;
	status: number;
	statusText: string;
};

export function Footer() {
	const [email, setEmail] = useState("");
	const [error, setError] = useState<string | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();
		setError(null);
		setSuccessMessage(null);
		try {
			const response = await axios.post<SubscriberResponse>(
				`${API_URL}/subscribers/${email}`,
				{},
				{
					withCredentials: true,
					headers: {
						"x-api-key": API_KEY,
					},
				},
			);

			if (response.data.status_code === 200) {
				setSuccessMessage("Subscribed successfully!");
				setEmail("");
			} else {
				setError(response.data.message);
			}
		} catch (err) {
			setError("Error subscribing. Please try again later.");
		}
	};

	const currentYear = new Date().getFullYear();

	return (
		<footer style={{ backgroundColor: "#171717", color: "#dadada" }} className="pt-10">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="grid grid-cols-1 gap-8 py-16 md:grid-cols-2 lg:grid-cols-5">
					<div className="flex flex-col items-start">
						<div className="mb-4">
							<Image
								src="https://d127u13u6yy1k7.cloudfront.net/static/images/logo/micrographer-logo-gold.png"
								alt="The Micrographer logo"
								width={200}
								height={50}
							/>
						</div>
						<p>© {currentYear} Maicro Labs, LLC. All Rights Reserved.</p>
					</div>
					<div>
						<h5 className="mb-4 text-lg font-semibold">MICROGRAPHER</h5>
						<ul className="space-y-2">
							<li>
								<Link href="/about" className="hover:underline">
									About
								</Link>
							</li>
							<li>
								<Link href="/contact" className="hover:underline">
									Contact
								</Link>
							</li>
							<li>
								<Link href="/tracking" className="hover:underline">
									Order Tracking
								</Link>
							</li>
							<li>
								<Link href="/registry" className="hover:underline">
									Art Registry
								</Link>
							</li>
							<li>
								<Link href="/faq" className="hover:underline">
									FAQs
								</Link>
							</li>
						</ul>
					</div>
					<div>
						<h5 className="mb-4 text-lg font-semibold">SITE INFORMATION</h5>
						<ul className="space-y-2">
							<li>
								<Link href="/terms" className="hover:underline">
									Terms & Conditions
								</Link>
							</li>
							<li>
								<Link href="/privacy" className="hover:underline">
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link href="/returns" className="hover:underline">
									Return Policy
								</Link>
							</li>
						</ul>
					</div>
					<div>
						<h5 className="mb-4 text-lg font-semibold">FOLLOW US ON</h5>
						<ul className="space-y-2">
							<li>
								<a
									href="https://www.instagram.com/micrographerco"
									className="flex items-center hover:underline"
								>
									<Instagram className="mr-2" /> Instagram
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/micrographer" className="flex items-center hover:underline">
									<Facebook className="mr-2" /> Facebook
								</a>
							</li>
							<li>
								<a
									href="https://www.youtube.com/channel/UCw-qmIs7_V79lCeUT2HzM_Q"
									className="flex items-center hover:underline"
								>
									<Youtube className="mr-2" /> Youtube
								</a>
							</li>
							<li>
								<a href="https://twitter.com/micrographerco" className="flex items-center hover:underline">
									<Twitter className="mr-2" /> Twitter
								</a>
							</li>
							{/* <li>
								<a
								href="https://www.tiktok.com/@micrographer"
								className="hover:underline flex items-center"
								>
								<Tiktok className="mr-2" /> TikTok
								</a>
							</li> */}
						</ul>
					</div>
					<div>
						<h5 className="mb-4 text-lg font-semibold">SUBSCRIBE</h5>
						<p className="mb-4">
							Subscribe to our monthly newsletter about print releases, discounts, and more! Follow our social
							media feeds for more frequent updates.
						</p>
						<form onSubmit={handleSubmit} className="flex">
							<input
								id="subscriberEmail"
								name="subscriberEmail"
								placeholder="Your email address"
								required
								type="email"
								className="rounded-l-md bg-white p-2 text-black"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<button
								type="submit"
								className="rounded-r-md p-2 text-black"
								style={{ backgroundColor: "#D6CD9E" }}
							>
								<ArrowRightIcon />
							</button>
						</form>
						{error && <p className="mt-2 text-red-500">{error}</p>}
						{successMessage && <p className="mt-2 text-green-500">{successMessage}</p>}
					</div>
				</div>
			</div>
		</footer>
	);
}
