"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export const initGA = (): void => {
	const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
	if (GOOGLE_ANALYTICS_ID) {
		ReactGA.initialize(GOOGLE_ANALYTICS_ID);
	} else {
		// TODO: Cloudwatch Metrics
		console.warn("GA4 Measurement ID not found");
	}
};

export const logPageView = (): void => {
	ReactGA.send("pageview");
};

export const logEvent = (action: string, category: string, label?: string): void => {
	ReactGA.event({
		category,
		action,
		label,
	});
};

export const logPurchase = (transactionId: string, value: number, currency: string): void => {
	ReactGA.event("purchase", {
		transaction_id: transactionId,
		value: value,
		currency: currency,
	});
};

export function AnalyticsInitializer() {
	const pathname = usePathname();
	const [isBannerVisible, setBannerVisible] = useState(false);

	useEffect(() => {
		const consent = localStorage.getItem("cookie_consent");
		if (consent === null) {
			setBannerVisible(true);
		} else if (consent === "accepted") {
			initGA();
			logPageView();
		}
	}, []);

	useEffect(() => {
		const consent = localStorage.getItem("cookie_consent");
		if (consent === "accepted") {
			logPageView(); // Log page view on route change
		}
	}, [pathname]);

	const handleAccept = () => {
		localStorage.setItem("cookie_consent", "accepted");
		setBannerVisible(false);
		initGA();
		logPageView();
	};

	const handleReject = () => {
		localStorage.setItem("cookie_consent", "rejected");
		setBannerVisible(false);
	};

	if (!isBannerVisible) return null;

	return (
		<div className="fixed bottom-0 left-0 right-0 z-50 bg-mg-gold p-4 text-mg-black shadow-lg">
			<div className="mx-auto flex max-w-7xl items-center justify-between px-3 sm:px-8">
				<div className="text-base">
					<p>
						We use cookies to enhance your experience. By using our site, you agree to our use of cookies.
					</p>
				</div>
				<div className="flex space-x-3">
					<button
						onClick={handleReject}
						className="px-4 py-2 text-mg-black underline transition hover:text-opacity-80"
					>
						Reject
					</button>
					<button
						onClick={handleAccept}
						className="rounded-md bg-mg-black px-6 py-3 text-mg-gold shadow-lg transition hover:bg-opacity-90"
					>
						Accept
					</button>
				</div>
			</div>
		</div>
	);
}
