"use client";

import axios from "axios";
import clsx from "clsx";
import { ShoppingBagIcon } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { type SessionCart } from "@/ui/types";

const API_URL = process.env.NEXT_PUBLIC_MICROGRAPHER_API_URL;

const API_KEY = process.env.NEXT_PUBLIC_MICROGRAPHER_API_KEY;

export const CartNavItem = () => {
	const [lineCount, setLineCount] = useState(0);

	useEffect(() => {
		const fetchCartData = async () => {
			try {
				const response = await axios.get(`${API_URL}/cart`, {
					withCredentials: true,
					headers: {
						"x-api-key": API_KEY,
					},
				});
				const checkout: SessionCart = response.data;
				const count = checkout.reduce((result, line) => Number(result) + Number(line.quantity), 0);
				setLineCount(count);
			} catch (error) {
				console.error("Error fetching cart data:", error);
			}
		};

		fetchCartData();
	}, []);

	return (
		<Link href="/cart" className="relative flex items-center" data-testid="CartNavItem">
			<ShoppingBagIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
			{lineCount > 0 ? (
				<div
					className={clsx(
						"absolute bottom-0 right-0 -mb-2 -mr-2 flex h-4 flex-col items-center justify-center rounded bg-neutral-900 text-xs font-medium text-white",
						lineCount > 9 ? "w-[3ch]" : "w-[2ch]",
					)}
				>
					{lineCount} <span className="sr-only">item{lineCount > 1 ? "s" : ""} in cart, view bag</span>
				</div>
			) : (
				<span className="sr-only">0 items in cart</span>
			)}
		</Link>
	);
};
